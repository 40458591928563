<template>
  <loader v-if="loading" :style="'box'"></loader>
  <div v-if="!loading">
  <div class="grid">
      <div class="col-12 xl:col-6">
          <div class="card height-100 flex flex-column">
              <div class="flex justify-content-center p-3 rounded-normal content-alt-bgcolor">
                  <!-- <img src="layout/images/widgets/feature-faq.svg" alt="feature-faq"> -->
                  <h2 class="m-0">E-Mail Versand</h2>
              </div>
              <div class="formgrid mt-3">
                  <div class="field col">
                      <div class="field-checkbox col justify-content-center">
                          <InputSwitch v-model="setupMail.enable" /><span class="ml-2">Mailversand aktivieren</span>
                      </div>
                  </div>
                  <div class="field col">
                      <div class="p-inputgroup">
                          <span class="p-inputgroup-addon">Ausgangsserver</span>
                          <InputText v-model="setupMail.host" placeholder="host13.ssl-net.net" />
                      </div>
                      <div class="p-inputgroup">
                            <span class="p-inputgroup-addon">Port</span>
                            <InputNumber v-model="setupMail.port" placeholder="587" :min=0 :max=65535 :useGrouping="false" />
                        </div>
                      <div class="p-inputgroup">
                          <span class="p-inputgroup-addon">Benutzername</span>
                          <InputText v-model="setupMail.user" placeholder="Benutzername" />
                      </div>
                      <div class="p-inputgroup">
                          <span class="p-inputgroup-addon">Passwort</span>
                          <InputText v-model="setupMail.password" type="password" placeholder="Passwort" />
                      </div>
                      <div class="p-inputgroup">
                          <span class="p-inputgroup-addon">Absender-Name</span>
                          <InputText v-model="setupMail.senderName" placeholder="Kunde" />
                      </div>
                      <div class="p-inputgroup">
                          <span class="p-inputgroup-addon">Absender-Email</span>
                          <InputText v-model="setupMail.senderMail" placeholder="alarm@klimacomputer.at" />
                      </div>
                  </div>
                  <div class="field col">
                      <div class="field-checkbox col justify-content-center">
                        <InputSwitch v-model="setupMail.secure" /><span class="ml-2">Sichere Verbindung</span>
                      </div>
                  </div>
                  <Divider />
              </div>
          </div>
      </div>

      <div class="col-12 xl:col-6">
          <div class="card height-100 flex flex-column">
              <div class="flex justify-content-center p-3 rounded-normal content-alt-bgcolor">
                  <!-- <img src="layout/images/widgets/feature-faq.svg" alt="feature-faq"> -->
                  <h2 class="m-0">Pushover</h2>
              </div>
              <div class="formgrid mt-3">
                <div class="field col">
                    <div class="field-checkbox col justify-content-center">
                        <InputSwitch v-model="setupPushover.enable" /><span class="ml-2">Pushover aktivieren</span>
                    </div>
                </div>
                <div class="field col">
                    <div class="p-inputgroup">
                        <span class="p-inputgroup-addon">API Token</span>
                        <InputText v-model="setupPushover.appToken" placeholder="fsdg4gdsf4d8sf4ds" />
                    </div>
                  </div>
                  <Divider />
              </div>
              <div class="flex justify-content-between align-items-center pt-3 mt-auto">
                  <small class="muted-text">Speichern nicht vergessen!</small>
              </div>
          </div>
      </div>
  </div>
      
      <div class="flex justify-content-end">
          <div class="flex align-items-center justify-content-center m-2">
              <Button label="Speichern" @click="saveConfig" />
          </div>
      </div>
  </div>
</template>

<script>
import SocketioService from '@/services/socketioService';

export default {
  name: 'configSysNotifications',
  data() {
      return {
          products: null,
          loading: false,
          setupMail: {},
          setupPushover: {}
      };
  },
  created() {},
  mounted() {
      this.init();
  },
  beforeUnmount() {
      this.products = null;
      this.loading = false;
      this.setupNetworkId = null;
      this.setupMail = null;
      this.setupPushover = null;
  },
  methods: {
      async init() {
          this.loading = true;
          await this.getParameter();
          this.loading = false;
      },
      async getParameter() {
          await this.getSystemNetwork();
      },
      async getSystemNetwork() {
          await SocketioService.getSystemNetwork((err, response) => {
              if (!err && response !== null) {
                  this.setupNetworkId = response._id;
                  this.setupMail = response.notifications.mail;
                  this.setupPushover = response.notifications.pushover;
              }
          });
      },
      toastMongoResponse(title, response) {
          if (response.acknowledged && response.matchedCount > 0 && response.upsertedCount === 0 && response.modifiedCount === 0) {
              if (title !== null) {
                  this.$toast.add({
                      severity: 'info',
                      summary: 'Keine Änderungen',
                      detail: title + ' modifiziert: ' + response.modifiedCount + ' , keine Änderung notwendig ',
                      life: 3000,
                  });
              }
              return 0;
              // document updated
          } else if (response.acknowledged && response.matchedCount > 0 && response.upsertedCount === 0 && response.modifiedCount > 0) {
              if (title !== null) {
                  this.$toast.add({
                      severity: 'success',
                      summary: 'Speichern erfolgreich',
                      detail: title + ' modifiziert: ' + response.modifiedCount + ' , alle Änderungen wurden gespeichert ',
                      life: 3000,
                  });
              }
              return 1;
              // document deleted
          } else if (response.acknowledged && response.deletedCount > 0) {
              if (title !== null) {
                  this.$toast.add({
                      severity: 'success',
                      summary: 'Löschen erfolgreich',
                      detail: title +  ' ' + response.deletedCount + ' Einträge erfolgreich gelöscht',
                      life: 3000,
                  });
              }
              return 2;
              // document created
          } else if (response.acknowledged && response.insertedId !== null) {
              if (title !== null) {
                  this.$toast.add({
                      severity: 'success',
                      summary: 'Konfiguration erstellt',
                      detail: title + ' Konfiguration [' + response.insertedId.toString() + '] erfolgreich erstellt',
                      life: 3000,
                  });
              }
              return 3;
          } else {
              if (title !== null) {
                  this.$toast.add({
                      severity: 'error',
                      summary: 'Speichern fehlgeschlagen',
                      detail: title + ' modifiziert: ' + response.modifiedCount + ' , Erstellt: ' + response.upsertedCount + ' [' + response.upsertedId + ']',
                      life: 3000,
                  });
              }
              return -1;
          }
      },
      async saveNetwork() {
          const returnObj = {
              _id: this.setupNetworkId,
              notifications: { 
                mail: { ...this.setupMail},
                pushover: { ...this.setupPushover }
              }
          }
          await SocketioService.setSystemNetwork(returnObj, (err, response) => {
              if (!err && response !== null) {
                  this.toastMongoResponse("Benachrichtigungs-Konfiguration", response);
              }
          });
      },
      async saveConfig() {
          this.loading = true;
          await this.saveNetwork();
          this.loading = false;
      },
  },
};
</script>

<style lang="scss" scoped>
.p-inputgroup-addon {
  width: 200px;
}
</style>